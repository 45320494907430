import { FLAG_DIET_COOKIE } from "shared-utils"

export const API_MOCK_URL =
  "https://d081a3fe-af4d-4671-90b0-d4d9cee39043.mock.pstmn.io"

export const TYK_API_URL =
  process.env.NEXT_PUBLIC_TYK_URL || "https://gtp-tyk.eproc.dev/udg-stg/"

export const FEDERATED_TYK_API_URL =
  process.env.NEXT_PUBLIC_FEDERATED_TYK_URL ||
  "https://gtp-tyk.eproc.dev/graphql/"

export const GRAPHQL_URL = FLAG_DIET_COOKIE
  ? `${process.env.NEXT_PUBLIC_AUTH_BASE_URL_BUYER}graphql`
  : FEDERATED_TYK_API_URL

export const POSTMAN_API_URL = process.env.NEXT_PUBLIC_POSTMAN_URL as string
export const FEDERATED_TYK_WS_URL = FEDERATED_TYK_API_URL.replace(
  "https",
  "wss"
)

export const AUTH = {
  DOMAIN:
    process.env.NEXT_PUBLIC_AUTH_DOMAIN || "https://auth-staging.eproc.dev",
  CLIENT_ID: process.env.AUTH_CLIENT_ID || "",
  AUDIENCE: process.env.AUTH_AUDIENCE || "",
  CLIENT_SECRET: process.env.AUTH_CLIENT_SECRET || "",
  NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET || "",
}
